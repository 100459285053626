import React from "react"
import {
    TransitionGroup,
    Transition,
} from "react-transition-group"

const timeout = 180;
const getStyles = {
    entering: {
        opacity: 0,
    },
    entered: {
        transition: `opacity ${timeout}ms ease-in-out`,
        opacity: 1,
    },
    exiting: {
        transition: `opacity ${timeout}ms ease-in-out`,
        opacity: 0,
    },
}

function PageTransition(props) {
    const { children } = props

    let _window = {};
    try {
        _window = window;
    } catch (e) { }
    const { location = {} } = _window;

    return (
        <TransitionGroup>
            <Transition
                key={location.pathname || ''}
                timeout={{
                    enter: timeout,
                    exit: 0,
                }}
            >
                {status => (
                    <div
                        style={{
                            ...getStyles[status],
                        }}
                    >
                        {children}
                    </div>
                )}
            </Transition>
        </TransitionGroup>
    )
}

export default PageTransition