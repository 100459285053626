import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./Header.scss"

const navItems = [
  {
    to: "/",
    value: "Home",
  },
  {
    to: "/about",
    value: "About",
  },
  {
    to: "/projects",
    value: "Projects",
  },
  {
    to: "/themes",
    value: "Change Theme",
  },
]

const removeAllForwardSlashes = path => path.replace(/\//gi, "")

function isActive(currentNavItem, activePath) {
  try {
    const path = removeAllForwardSlashes(activePath).toLowerCase()
    const navItem = removeAllForwardSlashes(currentNavItem).toLowerCase()
    if (navItem === "" && path === "") {
      return "active"
    }

    if (navItem !== "" && path.includes(navItem)) {
      return "active"
    }

    return ""
  } catch (e) {
    return ""
  }
}

const Header = props => {
  const { className = "", activePath } = props
  return (
    <header className={`layout__header`}>
      <nav className={`layout__header__nav`}>
        {navItems.map(item => (
          <div
            key={item.value}
            onClick={() => navigate(item.to)}
            className={`${isActive(
              item.to,
              activePath
            )} layout__header__nav__item`}
          >
            <a className={`${className}`}>{item.value}</a>
          </div>
        ))}
      </nav>
    </header>
  )
}

export default Header
