import React from "react"
import ReactDOM from "react-dom"
import classnames from "classnames"

import "./Grid.scss"

export const GridContext = React.createContext()

export function Grid(props) {
  // TODO: add render `as` prop so it doesn't have to be a div
  const { children, gutter, className = "", ...rest } = props
  // gutter needs to be divided by 2
  // used as padding for children
  // used as negative margin left/right for Grid
  const computedGutter = gutter ? gutter / 2 : 0

  return (
    <GridContext.Provider value={{ gutter: computedGutter }}>
      <div
        style={{ margin: `-${computedGutter}px` }}
        className={`pure-g ${className}`}
        {...rest}
      >
        {props.children}
      </div>
    </GridContext.Provider>
  )
}

export function Column(props) {
  const {
    children,
    base = "1",
    sm,
    md,
    lg,
    xl,
    className = "",
    style,
    ...rest
  } = props
  const { gutter } = React.useContext(GridContext)
  const classNames = classnames({
    [`pure-u-${base}`]: base,
    [`pure-u-sm-${sm}`]: sm,
    [`pure-u-md-${md}`]: md,
    [`pure-u-lg-${lg}`]: lg,
    [`pure-u-xl-${xl}`]: xl,
    [`${className}`]: className,
  })
  return (
    <div
      className={classNames}
      style={{
        padding: gutter,
        ...style,
      }}
      {...rest}
    >
      {children}
    </div>
  )
}
