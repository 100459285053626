import React from "react"
import { Link } from "gatsby"
import "./index.scss"
import Header from "../components/Header/Header"
import Breadcrumbs from "../components/Breadcrumbs"
import { useLocalStorage } from "react-use"
import { Grid, Column } from "../components/Grid/Grid"
import PageTransition from "./components/PageTransition";
const shortid = require("shortid")

export const CZaasThemeContext = React.createContext()

const lightTheme = {
  id: "du9OsnTcL",
  name: "Light",
  fg: "#222222",
  bg: "#efefef",
  primary: "#2779bd",
  "primary-light": "#56a0dc",
  "primary-dark": "#16446a",
  "text-on-primary": "#fff",
  error: "#E10711",
  "error-text": "#fff",
  "border-radius": 3,
  // shade: {
  //   lightest: '',
  //   lighter: '',
  //   base: '',
  //   dark: '',
  //   darker: '',
  // },
}
const darkTheme = {
  id: "1WCYDJyWs",
  name: "Dark",
  fg: "#fff",
  bg: "#222",
  primary: "#c20000",
  "primary-light": "#f50000",
  "primary-dark": "#8f0000",
  "text-on-primary": "#fff",
  error: "#E10711",
  "error-text": "#fff",
  "border-radius": 3,
}
const matrixTheme = {
  id: "gMAusZcsK",
  name: "Matrix",
  fg: "#cff4cf",
  bg: "#060b06",
  primary: "#08eb1f",
  "primary-light": "#2EF742",
  "primary-dark": "#07BC1A",
  "text-on-primary": "#000000",
  error: "#E10711",
  "error-text": "#ffffff",
  "border-radius": "3px",
}
const jsTheme = {
  id: "ZQlSdEZGo",
  name: "JS",
  fg: "#fff",
  bg: "#222",
  primary: "#ffe101",
  "primary-light": "#FFE734",
  "primary-dark": "#CDB500",
  "text-on-primary": "#000000",
  error: "#E10711",
  "error-text": "#ffffff",
  "border-radius": "0px",
}
const pinkPill = {
  id: "1Atu3JKWi",
  name: "Pink Pill",
  fg: "#fff",
  bg: "#222",
  primary: "#FF00E0",
  "primary-light": "#FF33E6",
  "primary-dark": "#BF00A8",
  "text-on-primary": "#fff",
  error: "#E10711",
  "error-text": "#ffffff",
  "border-radius": "30px",
}

export const localThemes = [
  lightTheme,
  darkTheme,
  matrixTheme,
  jsTheme,
  pinkPill,
]

const initialThemes = {
  ZQlSdEZGo: "JS",
  du9OsnTcL: "Light",
  "1WCYDJyWs": "Dark",
  gMAusZcsK: "Matrix",
  "1Atu3JKWi": "Pink Pill",
}

function recursiveCssVariableCreator(props) {
  const { theme, seed = "--" } = props
  return Object.keys(theme)
    .map(key => {
      if (key === "id" || key === "name") {
        return ""
      }
      const nextValue = theme[key]
      if (typeof nextValue === "object") {
        return recursiveCssVariableCreator({
          theme: nextValue,
          seed: `${seed}${key}-`,
        })
      }
      return `${seed}${key}: ${nextValue};`
    })
    .join("")
}
export function createThemeStyles(props) {
  try {
    const { theme, cssScope = "" } = props
    const useScope = cssScope.trim() !== ""
    const themeId = `CZTheme_${theme.id}_${theme.name}`
    const existingStylesTag = document.getElementById(themeId)

    if (!existingStylesTag) {
      const style = document.createElement("style")
      style.id = themeId
      style.type = "text/css"

      const cssVariables = recursiveCssVariableCreator({ theme })
      const styles = `${useScope ? cssScope : ":root"} { ${cssVariables}}`
      style.innerText = styles
      document.head.appendChild(style)
    }
  } catch (e) { }
}
export function deleteThemeStyles(props) {
  const { theme } = props
  const themeId = `CZTheme_${theme.id}_${theme.name}`

  try {
    const styleTag = document.getElementById(themeId)
    styleTag.remove()
  } catch (e) { }
}
export function useCZTheme() {
  const [themeStore, setThemeStore] = useLocalStorage(
    "cz-theme-store",
    initialThemes
  )
  const [activeTheme, _setActiveTheme] = useLocalStorage(
    "cz-theme-active",
    "ZQlSdEZGo"
  )
  React.useState(() => {
    const updatedThemeStore = { ...themeStore }
    Object.keys(initialThemes).forEach(themeId => {
      // If theme doesn't exist in themeStore, prepend it
      if (!updatedThemeStore[themeId]) {
        updatedThemeStore[themeId] = initialThemes[themeId]
      }
    })

    if (
      Object.keys(updatedThemeStore).length !== Object.keys(themeStore).length
    ) {
      setThemeStore(updatedThemeStore)
    }
  }, [themeStore])

  function setActiveTheme(requestedId) {
    const isLocalTheme = id => localThemes.find(t => t.id === id)
    const theme =
      isLocalTheme(requestedId) ||
      JSON.parse(window.localStorage.getItem(requestedId))
    if (theme) {
      _setActiveTheme(requestedId)
    }
  }

  function saveTheme(themeObject) {
    const id = shortid.generate()
    const clonedStore = { ...themeStore }

    if (themeObject.id) {
      // delete old theme
      window.localStorage.removeItem(themeObject.id)
      // remove old theme id from object and store
      delete clonedStore[themeObject.id]
      delete themeObject.id
    }

    window.localStorage.setItem(id, JSON.stringify({ id, ...themeObject }))
    setThemeStore({
      ...clonedStore,
      [id]: themeObject.name,
    })
    setActiveTheme(id)
  }

  React.useEffect(() => {
    const isLocalTheme = id => localThemes.find(t => t.id === id)
    const theme =
      isLocalTheme(activeTheme) ||
      JSON.parse(window.localStorage.getItem(activeTheme))

    if (theme) {
      createThemeStyles({ theme, cssScope: `.theme-${activeTheme}` })
    }
  }, [activeTheme])

  // Bug of what I think is happening:
  // Gatsby server side renders, then page is displayed to user
  // but if user has non-primary theme displayed; they see default colors (black & white)
  React.useEffect(() => {
    if (typeof window !== null) {
      window.addEventListener("DOMContentLoaded", () => {
        setActiveTheme(activeTheme)
      })
    }
  }, [])

  function deleteTheme(id) {
    try {
      if (localThemes.find(t => t.id === id)) {
        return
      }
      window.localStorage.removeItem(id)
      const updatedThemeStore = { ...themeStore }
      delete updatedThemeStore[id]
      setThemeStore(updatedThemeStore)
      if (id === activeTheme) {
        setActiveTheme(localThemes[0].id)
      }
    } catch (e) { }
  }

  return {
    themeStore,
    setThemeStore,
    activeTheme,
    setActiveTheme,
    saveTheme,
    deleteTheme,
  }
}

export default function Layout(props) {
  const {
    activeTheme,
    setActiveTheme,
    themeStore,
    setThemeStore,
    saveTheme,
    deleteTheme,
  } = useCZTheme()
  const [themeId, setThemeId] = React.useState("")

  React.useEffect(() => {
    setThemeId(activeTheme)
  }, [activeTheme !== themeId])

  return (
    <>
      <CZaasThemeContext.Provider
        value={{
          activeTheme,
          setActiveTheme,
          themeStore,
          setThemeStore,
          saveTheme,
          deleteTheme,
        }}
      >
        <div className={`layout theme-${themeId}`}>
          <Header
            className={`layout__nav`}
            activePath={props.location.pathname}
          />
          <main className="layout__content">
            <Breadcrumbs />
            <PageTransition>
              {props.children}
            </PageTransition>
          </main>
        </div>
      </CZaasThemeContext.Provider>
    </>
  )
}
