// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("/opt/build/repo/src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-index-mdx": () => import("/opt/build/repo/src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-projects-animal-selector-mdx": () => import("/opt/build/repo/src/pages/projects/animal-selector.mdx" /* webpackChunkName: "component---src-pages-projects-animal-selector-mdx" */),
  "component---src-pages-projects-chads-glass-mdx": () => import("/opt/build/repo/src/pages/projects/chads-glass.mdx" /* webpackChunkName: "component---src-pages-projects-chads-glass-mdx" */),
  "component---src-pages-projects-czaas-v-3-mdx": () => import("/opt/build/repo/src/pages/projects/czaas-v3.mdx" /* webpackChunkName: "component---src-pages-projects-czaas-v-3-mdx" */),
  "component---src-pages-projects-czaas-v-4-mdx": () => import("/opt/build/repo/src/pages/projects/czaas-v4.mdx" /* webpackChunkName: "component---src-pages-projects-czaas-v-4-mdx" */),
  "component---src-pages-projects-czaas-v-2-mdx": () => import("/opt/build/repo/src/pages/projects/czaas-v2.mdx" /* webpackChunkName: "component---src-pages-projects-czaas-v-2-mdx" */),
  "component---src-pages-projects-index-mdx": () => import("/opt/build/repo/src/pages/projects/index.mdx" /* webpackChunkName: "component---src-pages-projects-index-mdx" */),
  "component---src-pages-projects-mann-pools-mdx": () => import("/opt/build/repo/src/pages/projects/mann-pools.mdx" /* webpackChunkName: "component---src-pages-projects-mann-pools-mdx" */),
  "component---src-pages-projects-sightwords-mdx": () => import("/opt/build/repo/src/pages/projects/sightwords.mdx" /* webpackChunkName: "component---src-pages-projects-sightwords-mdx" */),
  "component---src-pages-projects-mb-fee-hw-mdx": () => import("/opt/build/repo/src/pages/projects/mb-fee-hw.mdx" /* webpackChunkName: "component---src-pages-projects-mb-fee-hw-mdx" */),
  "component---src-pages-projects-svelte-repl-projects-mdx": () => import("/opt/build/repo/src/pages/projects/svelte-repl-projects.mdx" /* webpackChunkName: "component---src-pages-projects-svelte-repl-projects-mdx" */),
  "component---src-pages-projects-testa-painting-mdx": () => import("/opt/build/repo/src/pages/projects/testa-painting.mdx" /* webpackChunkName: "component---src-pages-projects-testa-painting-mdx" */),
  "component---src-pages-themes-create-mdx": () => import("/opt/build/repo/src/pages/themes/create.mdx" /* webpackChunkName: "component---src-pages-themes-create-mdx" */),
  "component---src-pages-themes-edit-mdx": () => import("/opt/build/repo/src/pages/themes/edit.mdx" /* webpackChunkName: "component---src-pages-themes-edit-mdx" */),
  "component---src-pages-themes-index-mdx": () => import("/opt/build/repo/src/pages/themes/index.mdx" /* webpackChunkName: "component---src-pages-themes-index-mdx" */)
}

